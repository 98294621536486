.background {
    background: var(--background-color);
    height: 100vh; /* Ensure the background covers the full viewport height */
    //display: flex;
    //justify-content: center;
    //align-items: center;
    text-align: center;
}

.text-container {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.big-text {
    font-size: 200px;
    font-weight: bold;
    background: linear-gradient(to right, var(--accent-color-one) 30%, var(--accent-color-two) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    margin: 0; /* Remove default margin */
}

.little-text {
    font-size: 50px; /* Adjust font size as needed */
    margin: 0; /* Remove default margin */
    /* Optionally, add some padding if needed */
    padding-top: 10px; /* Adjust padding between the texts as needed */
    color: var(--text-color); /* Set a color for the text */
}

.arrow {
    color: var(--text-color);
    font-size: 50px;
    margin-top: 125px;
    cursor: pointer;
    animation: bounce 2s infinite;
    z-index: 1002;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

@media (max-width: 768px) {
    .big-text {
        font-size: 100px;
    }

    .little-text {
        font-size: 25px;
    }
}

@media (max-width: 480px) {
    .big-text {
        font-size: 100px;
    }

    .little-text {
        font-size: 25px;
    }
}