.card {
    display: flex;
    justify-content: center;
    //border-top: 2px solid var(--highlight-color-two);
    padding-top: 10px;
    padding-bottom: 50px;
}

.card-image {
    max-width: 150px;
    max-height: 150px;
    padding-right: 4rem;
    align-self: center;
}

.card-text {
    max-width: 500px;
    color: var(--text-color);
}

.title {
    font-size: 20px;
}

.paragraph {
    font-size: 16px;
}

.tech-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    gap: 20px;
}

.tech-item {
    background-color: var(--highlight-color-two);
    color: var(--highlight-color-one);
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .card {
        flex-direction: column;
        align-items: center;
    }
    .tech-list {
        justify-content: center;
    }

    .card-image {
        padding-right: 0px;
    }

    .card-text {
        text-align: center;
    }

    .tech-item {
        font-size: 12px;
    }
}

@media (max-width: 480px) {

}
