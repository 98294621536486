.projects-section {
    background-color: var(--background-color-accent);
    padding: 100px;
}

.projects-title {
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-color);
}

.projects-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

@media (max-width: 480px) {
    .projects-section {
        padding: 10px;
    }
}