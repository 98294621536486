.about-me-section {
    background-color: var(--background-color-accent);
    text-align: center;
    padding-top: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.paragraph-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Add some space between elements */
}

.about-me-title {
    color: var(--text-color);
}

.paragraph-text {
    padding: 20px;
    background-color: var(--background-color-accent);
    color: var(--text-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    text-align: left; /* Align text to the left */
}

.paragraph-image {
    height: 250px;
    width: 250px;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-25px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.card-section {
    display: flex;
    flex-direction: column;
}

.tech-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 40px; /* Add space above the tech stack */
}

.tech-title {
    color: var(--text-color);
}

.tech-card {
    position: relative;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: bounce 3s infinite;
    background-color: var(--background-color);
}

.tech-name {
    position: relative;
    margin-top: 10px;
    width: 75px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--background-color);
}

.card-section:hover .tech-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card-section:hover .tech-name {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.tech-logo {
    width: 50px;
    height: 50px;
}

.name {
    color: var(--text-color);
}

/* Media Queries for Responsiveness */

@media (max-width: 860px) {
    .paragraph-text {
        width: 75%;
    }

    .about-me-section {
        padding-top: 200px;
        padding-bottom: 20px;
    }

    .tech-section {
        padding-left: 30px;
        padding-right: 30px;
        gap: 50px;
        display: flex;
        flex-direction: row; /* Set direction to row */
        flex-wrap: wrap; /* Allow wrapping to create multiple rows */
        justify-content: center; /* Center the items horizontally */
    }
}
