.navbar {
    background-color: var(--background-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    transition: top 5s ease-out;
    z-index: 1000;
    box-sizing: border-box;
}

.navbar.home {
    position: relative;
}

.navbar.not-home {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--background-color);
    color: white;
    animation: dropDown 0.5s ease-in-out forwards;
    height: 8%;
}

@keyframes dropDown {
    0% {
        top: -100px;
    }
    100% {
        top: 0;
    }
}

.navbar-left .Home-Link {
    font-size: 2rem;
    font-weight: bold;
    color: var(--navbar-text-color);
    text-decoration: none;
}

.navbar-left .Home-Link:hover {
    color: var(--accent-color-one);
}

.navbar-right {
    display: flex;
}

.menu-toggle {
    display: none;
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--navbar-text-color);
}

.navbar-right .nav-links {
    list-style-type: none;
    display: flex;
    margin: 0;
    font-size: 25px;
    align-items: center;
}


.navbar-right .nav-links li {
    margin-right: 1rem;
}

.navbar-right .nav-links a {
    color: var(--navbar-text-color);
    text-decoration: none;
}

.navbar-right .nav-links a:hover {
    color: var(--accent-color-two);
}

.navbar-right .Github-Logo{
    max-height: 3rem;
    padding-right: 4px;
}

.navbar-right .Linkedin-Logo{
    max-height: 3rem;
}


/* Toggle Switch Styles */
.theme-toggle {
    width: 50px;
    height: 25px;
    background-color: var(--background-color-accent);
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.theme-toggle .toggle-thumb {
    width: 23px;
    height: 23px;
    background-color: var(--text-color);
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: transform 0.3s ease-in-out;
}

.theme-toggle.dark .toggle-thumb {
    transform: translateX(25px);
    background-color: var(--text-color);
}

.theme-toggle.dark {
    background-color: var(--background-color-accent);
}




/* Media Queries for Responsiveness */
@media (max-width: 925px) {
    .navbar {
        //flex-direction: column;
        //padding: 1rem;
    }

    .navbar-left, .navbar-right {
        //width: 100%;
        //text-align: center;
        //margin-bottom: 1rem;
    }

    .menu-toggle {
        display: block;
    }

    .navbar-right .nav-links {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1001;

        flex-direction: column;
        display: none;
        width: 100%;
        padding: 0;
        margin: 0;
        background-color: var(--background-color);

    }

    .navbar-right .nav-links.open {
        display: flex;
    }

    .navbar-right .nav-links li {
        margin: 0;
        padding: 1rem;
        border-top: 1px solid var(--navbar-text-color);
    }

    .Github-Logo {
        max-height: 4rem;
    }
    .Linkedin-Logo {
        max-height: 4rem;
    }
}

@media (max-width: 480px) {
    .navbar-left .Home-Link {
        font-size: 1.5rem;
    }

    .navbar-right .nav-links {
        font-size: 18px;
    }

    .Github-Logo {
        max-height: 3rem;
    }
    .Linkedin-Logo {
        max-height: 3rem;
    }
}