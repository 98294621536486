.footer-section {
    background-color: var(--background-color);
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 30px;
    padding-bottom: 30px;
}

.footer-text {
    color: var(--text-color);
    width: 25%;
    font-size: 12px;
}

.text-highlight {
    color: var(--accent-color-one);
}

/* Media Queries for Responsiveness */
@media (max-width: 1000px) {
    .footer-text {
        width: 75%;
    }
}

@media (max-width: 480px) {
    .footer-text {
        width: 75%;
    }
}