.expertise-section {
    background-color: var(--background-color);
    padding-top: 100px;
}


.expertise-title {
    font-size: 40px;
    text-align: center;
    color: var(--text-color);
}


.card-container {
    padding: 50px;
}

.card-wrapper {
    border-top: 2px solid var(--highlight-color-two);
}


