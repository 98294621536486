@import url('https://fonts.googleapis.com/css2?family=Nerko+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');


:root {
  --background-color: #e3dcfe;
  --background-color-accent: #d7d1f3;
  --text-color: #333;
  --navbar-text-color: #090132;
  --accent-color-one: #9475fd;
  --accent-color-two: #2dfb95;
  --highlight-color-one: #9475fd;
  --highlight-color-two: rgba(139, 105, 255, 0.20);
  --github-logo-height: 4rem;
}

[data-theme="dark"] {
  --background-color: #080123;
  --background-color-accent: #9777fd;
  --text-color: #d5cdfe;
  --navbar-text-color: #d5cdfe;
  --accent-color-one: #009688 ;
  --accent-color-two: #ff7043 ;
  --highlight-color-one: #16c1b1;
  --highlight-color-two: rgba(0 , 150, 136, 0.20);
  --github-logo-height: 4rem;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
